<template>
  <div class="flex justify-between">
    <div class="login-image">
      <img :src="logo" alt="Image Profile" />
      <div class="caption">
        <h5>{{ $t("welcomeInTurbo") }}</h5>
        <p>{{ $t("theLargestShipping") }}</p>
      </div>
    </div>
    <router-view class="parent-login"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: process.env.VUE_APP_CONFIGS_MAIN_LOGO_WHITE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_login.scss";
</style>
